define("discourse/plugins/discourse-nested-posts/discourse/services/nested-posts", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NestedPostsService extends _service.default {
    get isEnabled() {
      return this.siteSettings.nested_posts_enabled;
    }
  }
  _exports.default = NestedPostsService;
});